.homepage-footer-text {
    color: #0006;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 40px;
    div{
      margin-bottom: 10px;
    }
  }
  
  