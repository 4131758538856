.app-layout {
  padding: 50px;
  height: 100%;
  width: 50%;
}

.app-layout.is-rtl {
  direction: rtl;
}

@media (max-width: 1199px) {
  .app-layout {
    padding: 30px;
  }
}

@media (max-width: 991px) {
  .app-layout {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .app-layout {
    padding: 40px;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .app-layout {
    padding: 20px 20px 80px;
  }
}
