/* Common Styles */
.language-label {
  font-size: 14px;
  color: black;
}

.language-flag {
  width: 25px;
  border-radius: 3px;
  overflow: hidden;
}

/* Language Trigger Related Styles */
.language-trigger {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 5px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.arrow-down-icon {
  transition: transform 0.1s linear;
}

.language-trigger[data-state="open"] > .arrow-down-icon {
  transform: rotate(180deg);
}

.language-trigger:hover {
  background-color: #fbfbfb;
}

.language-trigger-content {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 9px;
  padding-right: 10px;
}

/* Language Content Related Styles */
.language-content {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 300px;
  flex-wrap: wrap;
}

.language-content-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.1s linear;
  flex-basis: calc(50% - 2.5px);
}

/* Language Switch Layout Related Styles */

.language-switch-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 10px 0 0;
}

/* Media Related Styles */
@media (min-width: 1024px) {
  .language-content-item:hover {
    background-color: #e9f3fe;
  }
}

@media (max-width: 1024px) {
  .language-content-item:focus {
    background-color: #e9f3fe;
  }

  .language-content-item:active {
    background-color: #e9f3fe;
  }

  .language-content-item {
    transition: none;
  }
}
