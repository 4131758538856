.payment-form-inputs {
  margin-top: 50px;
}

.payment-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.payment-form-input {
  background: #f5f5f5;
  border: none;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 17px 22px;
  width: 100%;
}

.with-adornment {
  padding-right: 140px;
}

.payment-form-buttons {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 9px;
}

.payment-form-btn {
  padding: 11px 50px;
}

@media (max-width: 1199px) {
  .payment-form-inputs {
    margin-top: 40px;
  }

  .payment-form-inputs {
    gap: 20px;
  }

  .payment-form-btn {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .payment-form-inputs {
    gap: 20px;
  }

  .payment-form-btn {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .payment-form-inputs {
    margin-top: 50px;
  }

  .payment-form-inputs {
    gap: 25px;
  }
}
