.input-field {
  background: white;
  color: #000;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  outline: none;
}

.input-field-fullWidth {
  width: 100%;
}

.input-number-field::-webkit-inner-spin-button,
.input-number-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
