.full-screen-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.scaling-icon {
  width: 100px;
  animation: scaleAnimation 2s infinite alternate; /* Change the duration as needed */
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
