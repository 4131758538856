.heading-container {
  margin-bottom: 30px;
}

.heading-text {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  position: relative;
  line-height: 1.2;
}

.heading-text::after {
  background-color: #3880ff;
  border-radius: 5px;
  content: "";
  height: 3px;
  left: 50%;
  position: absolute;
  top: 120%;
  transform: translateX(-50%);
  width: 90%;
}

@media (max-width: 1199px) {
  .heading-container {
    margin-bottom: 15px;
  }

  .heading-text {
    font-size: 18px;
  }

  .heading-text::after {
    top: 110%;
    height: 2px;
  }
}
