.calculate-payment-input {
  border-radius: 8px;
  height: 42px;
  padding: 0 6px 0 6px;
  margin-left: 30px;
  margin-right: 10px;
}

.calculate-payment-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.calculate-payment-input-text {
  color: #0009;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .calculate-payment-input {
    margin-left: 20px;
  }

  .calculate-payment-text {
    font-size: 14px;
  }

  .calculate-payment-input-text {
    font-size: 14px;
  }
}
